import { Button } from '@components/core'
import { useRouter } from 'next/router'
import { FC } from 'react'

const NotFound404: FC<{ text: string }> = ({ text }) => {
	const router = useRouter()
	return (
		<>
			<div className="my-auto bg-color-1 flex flex-col justify-around items-center pt-20 3xs:p-20">
				<div className="flex flex-col justify-center items-center">
					<img src="/images/profile/pacman-404.svg" alt="404 Image" className="absolute" height={300} width={300} />
					<h1 className="text-6xl text-color-6 font-black">..Oops!</h1>
					<p className="text-base text-color-6 font-semibold text-center my-4 w-60">{text}</p>
				</div>
				<Button variant="outlined" onClick={() => router.back()} size="sm">
					Go Back
				</Button>
			</div>
		</>
	)
}

export default NotFound404
