import Layout from '@components/Layout/Layout'
import NotFound404 from '@components/common/NotFound404'
import { FC } from 'react'

const Custom404: FC = () => {
	return (
		<>
			<Layout title="404">
				<NotFound404 text="Sorry, the page you are looking for cannot be found" />
			</Layout>
		</>
	)
}

export default Custom404
